<template>
  <div class="page" v-loading="downloadLoading" element-loading-text="Loading...">
    <div class="banner">
      <h2 class="banner-title">HOLOWITS</h2>
      <div class="search-bar">
        <i class="el-icon-search search-icon"/>
        <input class="search-input" type="text" :placeholder="$i18n.t('documentation.searchPlaceholder')"
               v-model="queryParam.keyWords"
               @keyup.enter="search">
        <div class="search-button" @click="search">{{ $i18n.t('documentation.search') }}</div>
      </div>
    </div>
    <div class="container doc-section">
      <el-row :gutter="10">
        <el-col :md="18">
          <section class="doc-content">
            <div class="doc-filter">

              <div class="fixed-clear-btn">
                <el-button size="small" @click="clearAllChecked" style="margin-left: 10px">
                  {{ $i18n.t('common.clear') }}
                </el-button>
              </div>

              <el-checkbox-group v-model="queryParam.languageFilters" @change="getList">
                <div class="doc-filter-list">
                  <span class="doc-filter-label">{{ $i18n.t('documentation.languageFilter') }}</span>
                  <div class="doc-filter-value" style="padding-right: 50px;">
                      <div class="doc-filter-item" style="margin-bottom: 15px;" v-for="(item,index) in languages" :key="index">
                        <el-checkbox :label="item.code"><div style="width: 115px;">{{ item.name }}</div></el-checkbox>
                      </div>
                  </div>
                </div>
              </el-checkbox-group>

              
              <div class="filter-content">
                <span class="doc-filter-label">Type</span>
                <ul>
                  <template>
                    <li :class="{'active': typeSelectedVal === item.filter}" v-for="item in menu" :key="item.filter" @click="typeSelect(item)">{{ item.label }}</li>
                  </template>
                </ul>
              </div>

              <div v-for="item in menu" :key="item.filter">
                <el-checkbox-group v-show="typeSelectedVal === item.filter" v-model="queryParam[`${item.name}Filter`]" @change="filterChange(item.name)" style="box-shadow: 0px 0px 4px 0px #ccc; padding: 12px; margin-top: 12px;">
                  <div class="doc-filter-list">
                    <div class="doc-filter-value" v-if="Object.keys(menuActive).length">
                        <div :class="['doc-filter-item','t-selector', menuActive[`${item.name}Active`] === index? 'active': '']" @click="mouseOver(item.name, index)" v-for="(item1,index) in filterKeys[item.filter]" :key="index">
                          {{item1}}
                        </div>
                    </div>
                  </div>
                  <div v-show="menuActive[`${item.name}Active`] === index2" class="doc-filter-selectors" v-for="(item2,index2) in filterKeys[item.filter]" :key="index2">
                    <div v-if="filterData[item.filter]">
                      <el-checkbox v-for="(i, index3) in filterData[item.filter][item2]" :key="index3" :label="i">{{ i.value }}</el-checkbox>
                    </div>
                  </div>
                </el-checkbox-group>
              </div>

              <!-- <el-checkbox-group v-show="typeSelectedVal === 'Product'" v-model="queryParam.productFilter" @change="productFilterChange" style="box-shadow: 0px 0px 4px 0px #ccc; padding: 12px; margin-top: 12px;">
                <div class="doc-filter-list">
                  <div class="doc-filter-value">
                      <div :class="['doc-filter-item','t-selector', productActive === index? 'active': '']" @click="mouseOver('product', index)" v-for="(item,index) in filterKeys['Product']" :key="index">
                        {{item}}
                      </div>
                  </div>
                </div>
                <div v-show="productActive === index" class="doc-filter-selectors" v-for="(item,index) in filterKeys['Product']" :key="index">
                  <el-checkbox v-for="(i, index) in filterData['Product'][item]" :key="index" :label="i">{{ i.value }}</el-checkbox>
                </div>
              </el-checkbox-group>

              <el-checkbox-group v-show="typeSelectedVal === 'Solution'" v-model="queryParam.solutionFilter" @change="solutionFilterChange" style="box-shadow: 0px 0px 4px 0px #ccc; padding: 12px; margin-top: 12px;">
                <div class="doc-filter-list">
                  <div class="doc-filter-value">
                      <div :class="['doc-filter-item','t-selector', solutionActive === index? 'active': '']" @click="mouseOver('solution', index)" v-for="(item,index) in filterKeys['Solution']" :key="index">
                        {{item}}
                      </div>
                  </div>
                </div>
                <div v-show="solutionActive === index" class="doc-filter-selectors" v-for="(item,index) in filterKeys['Solution']" :key="index">
                  <el-checkbox v-for="(i, index) in filterData['Solution'][item]" :key="index" :label="i">{{ i.value }}</el-checkbox>
                </div>
              </el-checkbox-group> -->

              <el-checkbox-group v-model="queryParam.typeFilters" @change="getList" style="box-shadow: 0px 0px 4px 0px #ccc; padding: 12px; margin-top: 12px;">
                <div class="doc-filter-list">
                  <span class="doc-filter-label">{{ $i18n.t('documentation.typeFilter') }}</span>
                  <div class="doc-filter-value">
                      <div :class="['doc-filter-item','t-selector', menuActive[`typeActive`] === index? 'active': '']" @click="mouseOver('type', index)" v-for="(item,index) in filterKeys['Type']" :key="index">
                        {{item}}
                      </div>
                  </div>
                </div>
                <div v-if="menuActive[`typeActive`] >= 0">
                  <div v-show="menuActive[`typeActive`] === index" class="doc-filter-selectors" v-for="(item,index) in filterKeys['Type']" :key="index">
                    <el-checkbox v-for="(i, index) in filterData['Type'][item]" :key="index" :label="i"><div style="width: 185px;">{{ i.value }}</div></el-checkbox>
                  </div>
                </div>
                
              </el-checkbox-group>

              <el-checkbox-group v-model="queryParam.formatFilters" @change="getList" style="margin-top: 12px;">
                <div class="doc-filter-list">
                  <span class="doc-filter-label">{{ $i18n.t('documentation.formatFilter') }}</span>
                  <div class="doc-filter-value">
                      <div class="doc-filter-item" style="margin-bottom: 15px;" v-for="(item,index) in MaterialFormat" :key="index">
                        <el-checkbox :key="index" :label="item.code">{{ item.name }}</el-checkbox>
                      </div>
                  </div>
                </div>
              </el-checkbox-group>
              
              <div v-show="queryParam.typeFilters.length > 0" class="t-tag">
                <el-tag :key="index" v-for="(item, index) in queryParam.typeFilters" closable :disable-transitions="false" @close="tagClose(item)">
                  {{item.value}}
                </el-tag>
                <el-tag class="t-clear" @click="clearAll">Clear all</el-tag>
              </div>

              <el-alert v-if="!this.$store.state.client.user.id" type="error" center>
                <div slot="title" style="color: #000;">
                  Login to get more materials &nbsp;&nbsp;&nbsp;&nbsp; <span @click="login" style="color: #C7000B; cursor: pointer;">Login</span>
                </div>
              </el-alert>

            </div>

            <div class="doc-result">
              <span class="doc-result-text">
                {{ $i18n.t('common.RefineBy') }}（{{ total }} {{ $i18n.t('documentation.result') }}）
              </span>

              <div>
                <el-select size="small" v-model="pageParam.sort" @change="getList">
                  <el-option value="publishDate" :label="$i18n.t('documentation.publishDate')"/>
                  <el-option value="downloadTimes" :label="$i18n.t('documentation.downloadTimes')"/>
                </el-select>
              </div>

            </div>

            <div class="doc-result-content" v-loading="loading">
              <el-checkbox-group v-model="checkFiles" @change="handleCheckedCitiesChange">
                  <div class="doc-result-list" v-for="(item,index) in list" :key="index">
                   <!-- <el-checkbox :label="item.fileId"><span></span></el-checkbox> -->
                    <el-image :src="domainUrl+item.iconUrl" class="doc-result-list-icon" fit="scale-down"/>
                    <div class="doc-result-list-content">
                      <a v-if="item.docTypeValue === 'ppt'"  class="doc-result-list-name" :href="`https://view.officeapps.live.com/op/view.aspx?src=${domainUrl+item.viewUrl}`" target="_blank">{{ item.name }}</a>
                      <a v-else-if="item.docTypeValue === 'html'"  class="doc-result-list-name" :href="`/htmldoc/${item.id}`" target="_blank">{{ item.name }}</a>
                      <a v-else class="doc-result-list-name" :href="domainUrl+item.viewUrl" target="_blank">{{ item.name }}</a>
                      <div class="doc-result-list-date">{{ item.publishDate }} | {{ item.docSize }}（MB） <span class="warn" v-if="item.authority === '1'"><i class="el-icon-lock"></i> Staff Only</span></div>
                    </div>
                    <div class="doc-result-download" @click="downloadFile(item)">
                      <i class="el-icon-download"/>
                      <span>{{ $i18n.t('documentation.download') }}</span>
                    </div>
                    <div class="doc-result-download" title="Copy Link" @click="share(item)">
                      <i class="el-icon-share"/>
                      <span>{{ $i18n.t('documentation.share') }}</span>
                    </div>
                  </div>
              </el-checkbox-group>

              <div class="doc-result-footer">
                <!-- 批量下载 -->
               <!-- <div class="doc-result-bat-download"
                     :class="{'bat-download-active':checkFiles.length}"
                     @click="batDownload">
                  <i class="el-icon-download"/>
                  <span>{{ $i18n.t('documentation.batDownload') }}</span>
                </div> -->
                <div class="doc-result-pages">
                  <span class="doc-result-pages-text">{{ $i18n.t('documentation.paginalNumber') }}</span>
                  <el-select size="small" v-model="pageParam.size" @change="getList">
                    <el-option label="10" :value="10"/>
                    <el-option label="20" :value="20"/>
                    <el-option label="30" :value="30"/>
                  </el-select>
                  <span class="doc-result-pages-text" v-if="$i18n.locale === 'zh'">条</span>
                </div>
              </div>

              <div class="flex-end">
                <el-pagination background layout="prev, pager, next" :current-page.sync="pageParam.current" :page-count="Number(pages)" @current-change="getList"/>
              </div>

            </div>

          </section>
        </el-col>

        <el-col :md="6">
          <section class="hot-doc">
            <div class="hot-doc-title">{{ $i18n.t('documentation.hotDoc') }}</div>
            <ul class="hot-doc-wrap">
              <li class="hot-doc-list" v-for="(item,index) in hotList" :key="index">
                <a :href="domainUrl+item.viewUrl" target="_blank" class="hot-doc-link">
                  <!-- <span class="hot-doc-tag"></span> -->
                  <span class="hot-doc-text">{{ item.name }}</span>
                </a>
              </li>
            </ul>
          </section>
        </el-col>

      </el-row>
    </div>
  </div>
</template>
<script>
  import {getHotList, getList, download, addDownloadTimes, getMeterialTypes} from '@/api/documentation';
  import {downloadFile, initDicts} from '@/utils/commons';
  import {debounce} from "lodash";

  export default {
    name: 'documentation',
    data() {
      return {
        list: [],
        hotList: [],
        total: 0,
        pages:0,
        loading: true,
        downloadLoading:false,
        isIndeterminate: false,
        checkAll: false,
        checkFiles: [],

        queryParam: {
          'keyWords': null,
          'productFilter': [],
          'solutionFilter': [],
          'typeFilters': [],
          'formatFilters': [],
          'languageFilters': []
        },

        pageParam: {
          'current': 1,
          'extra': {},
          'order': 'descending',
          'size': 10,
          'sort': 'publishDate',
        },

        filterData: {},
        filterKeys: {},
        batList: [],
        MaterialType:[],
        MaterialFormat:[],
        productType:[],
        productActive: 0,
        solutionActive: 0,
        typeActive: 0,
        languages: [
          {
            code: 'en', name: 'English',
          },
          {
            code: 'ar', name: 'اللغة العربية',
          },
          {
            code: 'fr', name: 'Français',
          },
          {
            code: 'jp', name: '日本語',
          },
          {
            code: 'br', name: 'Português (Brasil)',
          },
          {
            code: 'ru', name: 'Русский язык',
          },
          {
            code: 'es', name: 'Español',
          },
          {
            code: 'th', name: 'ภาษาไทย',
          },
          {
            code: 'tr', name: 'Türkçe',
          },
          {
            code: 'gk', name: 'Ελληνικά',
          },
        ],
        menu: [],
        menuActive: {},
        typeSelectedVal: 'Product'
      };
    },
    computed: {
      // filterCheckedCase() {
      //   let {productFilter, typeFilters, formatFilters} = this.queryParam;
      //   return [...productFilter, ...typeFilters, ...formatFilters];
      // },
    },
    async created() {
      await this.getMeterialTypes()
    },
    mounted() {
      this.initData();
      initDicts(['MaterialType','MaterialFormat','productType','MaterialLanguage']).then(res => {
        // this.MaterialType = res.MaterialType;
        this.MaterialFormat = res.MaterialFormat;
        this.languages = res.MaterialLanguage;
        // this.productType = res.productType;
      });
    },
    methods: {
      typeSelect(item) {
        this.typeSelectedVal = item.filter
      },
      share(item) {
        let value = `https://resources.holowits.com/${item.viewUrl}`;
        this.$copyText(value).then(res => {
          this.$message.success('Link copied')
        }).catch(err => {
          this.$message.error('Can not copy')
        })
      },
      clearAll() {
        this.queryParam.typeFilters = []
        this.getList()
      },
      tagClose(item) {
        for (let index in this.queryParam.typeFilters) {
          let {type, value} = this.queryParam.typeFilters[index];
          if (type === item.type && value === item.value) {
            this.queryParam.typeFilters.splice(index, 1);
            break;
          }
        }
        this.getList()
      },
      async getMeterialTypes() {
        const {data: {data, isSuccess}} = await getMeterialTypes()
        if (isSuccess) {
          let obj = {}
          data.forEach(item => {
            if (!obj[item.type]) {
              obj[item.type] = {}
            }
            if (!obj[item.type][item.key]) {
              obj[item.type][item.key] = []
            }
            obj[item.type][item.key].push({type: item.key, value: item.value, id: item.id})
          })
          let keys = {}
          let types = []
          for (let item in obj) {
            keys[item] = Object.keys(obj[item])
            if (item !== 'Type') {
              types.push({
                filter: item,
                label: this.$i18n.t(`documentation.${item.toLowerCase()}Filter`),
                name: item.toLowerCase()
              })
              this.queryParam[`${item.toLowerCase()}Filter`] = []
            }
            this.menuActive[`${item.toLowerCase()}Active`] = 0
          }
          console.log(types)
          this.menu = types
          this.filterData = obj
          this.filterKeys = keys
        }
      },
      mouseOver(type, index) {
        // this.menuActive[`${type}Active`] = index
        this.$set(this.menuActive, `${type}Active`, index)
        this.$nextTick(() => {
          this.$forceUpdate()
        })
        // if (type === 'product') {
        //   this.productActive = index
        // }
        // if (type === 'solution') {
        //   this.solutionActive = index
        // }
        // if (type === 'type') {
        //   this.typeActive = index
        // }
      },
      login() {
        this.$router.push({path: 'login'})
      },
      initData() {
        getHotList().then(res => {
          this.hotList = res.data.data;
        });
        this.getList();
      },

      filterChange(key) {
        for(let item of this.menu) {
          let name = item.name
          if (key !== name) {
            this.queryParam[`${name}Filter`] = []
          }
        }
        console.log(this.queryParam)
        this.getList();
      },

      productFilterChange(val) {
        this.queryParam.solutionFilter = []
        // if (val.length !== 1) {
        //   val.shift();
        // }
        this.getList();
      },

      solutionFilterChange(val) {
        this.queryParam.productFilter = []
        // if (val.length !== 1) {
        //   val.shift();
        // }
        this.getList();
      },

      search() {
        this.pageParam.current = 1;
        this.getList();
      },
      getList: debounce(function () {
        // let productFilter = this.queryParam.productFilter;
        // let solutionFilter = this.queryParam.solutionFilter;
        let typeFilters = this.queryParam.typeFilters;

        let filters = []
        for(let {name} of this.menu) {
          filters.push(...this.queryParam[`${name}Filter`])
        }

        let params = {
          ...this.pageParam,
          model: {
            ...this.queryParam,
            // productFilter: productFilter.length ? productFilter : null,
            // solutionFilter: solutionFilter.length ? solutionFilter : null,
            filterIds: [...filters.map(item => item.id),...typeFilters.map(item => item.id)]
          },
        };
        this.loading = true;
        getList(params).then(res => {
          this.list = res.data.data.records;
          this.total = res.data.data.total;
          this.pages = res.data.data.pages;
          this.initBatCheck();
        }).finally(() => {
          this.loading = false;
        });
      }, 1000, {'trailing': true}),
      clearAllChecked() {
        this.queryParam = {
          'keyWords': null,
          'productFilter': [],
          'solutionFilter': [],
          'typeFilters': [],
          'formatFilters': [],
        };
        this.getList();
      },

      batDownload() {
        this.downloadLoading = true
        //记录下载次数
        this.checkFiles.forEach((fileId) => {
          let fileInfo = this.list.find(item => item.fileId === fileId);
          if (fileInfo) {
            addDownloadTimes(fileInfo).finally(()=>{
              this.downloadLoading  = false;
            });
          }
        });
        //下载文件
        download(this.checkFiles).then(response => {
          downloadFile(response);
        });
        this.downloadLoading  = false;
      },

      downloadFile(item) {
        // this.downloadLoading = true
        //记录下载次数
        addDownloadTimes(item);
        //如果文件过大 大于30M 比如 exe video 文件使用href下载

        // if(Number.parseInt(item.docSize)>30){
        //   window.open(this.domainUrl+item.viewUrl)
        //   this.downloadLoading = false
        //   return
        // }
        if (!this.$store.state.downloadList.fileNames.includes(item.name)) {
          this.$store.commit('downloadList/setList', {
            [item.name]: {
                            name: item.name,
                            status: 'await',
                            url: item.viewUrl,
                            cancel: null,
                            process: 0
                          }
          });
          this.$store.commit('downloadList/setFileNames', item.name);
          this.$store.commit('downloadList/setStatus', {
            [item.name]: {
                            status: true,
                          }
          });
        } else {
          let downloadItem = this.$store.state.downloadList.list[item.name];
          if (downloadItem.status === 'stop' || downloadItem.status === 'error') {
            this.$store.commit('downloadList/setListProperty', {
                key: item.name,
                property: 'status',
                value: 'await',
            });
            this.$store.commit('downloadList/setStatusProperty', {
              key: item.name,
              property: 'status',
              value: true,
            });
          }
          this.$message.info('Downloading...');
        }
        
        /*if ("exe" === item.docTypeValue || "video" === item.docTypeValue) {
          window.open(this.domainUrl+item.viewUrl)
          this.downloadLoading = false
          return
        }*/
        const meta = {
          "isHolowits": true,
        };
        //下载文件
        // download([item.fileId],meta).then(response => {
        //   downloadFile(response);
        // }).finally(() => {
        //   this.downloadLoading = false
        // });
      },

      handleCheckAllChange(val) {
        this.checkFiles = val ? this.list.map(i => i.fileId) : [];
        this.isIndeterminate = false;
      },
      handleCheckedCitiesChange(value) {
        let checkedCount = value.length;
        this.checkAll = checkedCount === this.list.length;
        this.isIndeterminate = checkedCount > 0 && checkedCount < this.list.length;
      },

      initBatCheck() {
        this.checkAll = false;
        this.isIndeterminate = false;
        this.checkFiles = [];
      },
    },
  };
</script>
<style scoped lang="less">
  .filter-content {
    display: flex;
    align-items: center;
    ul {
      display: flex;
      flex-wrap: wrap;
      li {
        font-size: 14px;
        padding: 5px 10px;
        border: 1px solid transparent;
        cursor: pointer;
        margin: 0 3px;
        position: relative;
      }
      .active {
        border: 1px solid var(--themeColor);
        color: var(--themeColor);
      }
    }
  }

  .banner {
    height: 320px;
    background-image: url(../../assets/image/documentation/banner.jpg);
    background-size: 100% auto;
    background-position: center;
    background-repeat: no-repeat;
    color: #FFFFFF;
    padding-top: 30px;
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    &:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      right: 0;
      top: 0;
      background-color: rgba(0, 0, 0, 0.29);
      pointer-events: none;
    }

    .banner-title {
      font-size: 30px;
      font-weight: bold;
      color: #FFFFFF;
      line-height: 60px;
      position: relative;
      z-index: 2;
      margin-bottom: 40px;
    }
  }

  .search-bar {
    width: 80%;
    max-width: 750px;
    background-color: #FFFFFF;
    height: 50px;
    border-radius: 48px;
    display: flex;
    align-items: center;
    overflow: hidden;
    position: relative;
    z-index: 2;
    box-shadow: 0 4px 8px 4px rgba(0, 0, 0, 0.08);

    .search-icon {
      font-size: 24px;
      margin: 0 12px 0 24px;
      color: #999999;
      position: relative;
      z-index: 10;
    }

    .search-input {
      flex: 1;
      width: 100%;
      height: 50px;
      box-shadow: none;
      outline: none;
      font-size: 18px;
      padding-left: 60px;
      margin-left: -55px;
      border-radius: 48px 0 0 48px;
      border: 1px solid transparent;
      position: relative;
      z-index: 3;
      color: #333333;

      &:focus + .search-icon {
        color: #c7000b;
      }
    }

    .search-button {
      cursor: pointer;
      color: #FFFFFF;
      font-size: 16px;
      background-color: var(--themeColor);
      height: 100%;
      width: 80px;
      padding-right: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      user-select: none;

      &:active {
        opacity: .8;
      }
    }
  }

  .page {
    background-color: #F9F9F9;
  }

  .doc-section {
    padding: 10px 0 40px;
    min-height: 60vh;
  }

  .doc-content {
    background-color: #FFFFFF;
    padding: 10px 12px 30px;
    margin-bottom: 20px;

    .doc-filter {
      position: relative;

      .fixed-clear-btn {
        position: absolute;
        right: 0;
        top: 6px
      }

      .doc-filter-list {
        font-size: 14px;
        display: flex;
        align-items: baseline;
        // padding-top: 12px;
      }

      .doc-filter-selectors {
        background-color: #f6f7f8;
        border: 1px solid #e5e5e5;
        padding: 10px;
        /deep/.el-checkbox {
          padding: 4px 0;
        }
      }

      .doc-filter-label {
        min-width: 70px;
        font-weight: 600;
        padding-right: 10px;
      }

      .doc-filter-value {
        flex: 1;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        margin-right: -20px;
        margin-bottom: -1px;
      }

      .doc-filter-item {
        // margin-bottom: 15px;
        margin-right: 20px;
      }
      .t-tag {
        margin: 5px 0;
        border: 1px solid #e5e5e5;
        padding: 10px 10px 0 10px;
        .t-clear {
          cursor: pointer;
          background-color: #FFFFFF;
          &:hover {
            background-color: #f9e6e7;
          }
        }
        .el-tag {
          margin-bottom: 10px;
          margin-right: 10px;
        }
      }
      .t-selector {
        cursor: pointer;
        padding: 5px 12px;
        border: 1px solid transparent;
        transition: all 0.1s;
        font-size: 12px;
        font-weight: 600;
        flex-shrink: 0;
        user-select: none;
        
        &.active {
          background-color: #f6f7f8;
          border: 1px solid #e5e5e5;
          border-bottom-color: transparent;
          color: #c7000b;
        }
      }
    }

    .doc-result {
      display: flex;
      align-items: baseline;
      padding: 20px 0;
      justify-content: space-between;
      border-bottom: 1px solid #F2F2F2;

      .doc-result-text {
        font-size: 15px;
        font-weight: bold;
        color: #000000;
      }

      .el-select {
        width: 200px;
      }
    }

    .doc-result-content {
      z-index: 1;
    }

    .doc-result-list {
      padding: 15px 0;
      border-bottom: 1px solid #F2F2F2;
      font-size: 14px;
      display: flex;
      align-items: center;

      .doc-result-list-content {
        flex: 1;
        width: 100%;
        padding-left: 10px;
      }

      .doc-result-list-icon {
        width: 36px;
        height: 36px;
        padding-left: 4px;
      }

      .doc-result-list-name {
        font-weight: 400;
        color: #333333;
        margin-bottom: 6px;
        cursor: pointer;
        display: block;

        &:hover {
          color: rgba(199, 0, 11, 0.76);
        }
      }

      .doc-result-list-date {
        font-size: 12px;
        font-weight: 400;
        color: #999999;
        .warn {
          background-color: #c7000b;
          color: #FFFFFF;
          border-radius: 3px;
          padding: 2px 6px;
          margin-left: 10px;
        }
      }

      .doc-result-download {
        padding: 12px 10px;
        color: var(--themeColor);
        cursor: pointer;
        user-select: none;
        position: relative;
        &+.doc-result-download::before {
          position: absolute;
          content: "|";
          color: #c7000b;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
        }

        i {
          margin-right: 2px;
        }

        &:active, &:hover {
          opacity: .7;
        }
      }
    }

    .doc-result-footer {
      padding: 15px 0;
      display: flex;
      align-items: center;

      .doc-result-bat-checkbox-text {
        font-weight: 400;
        color: #333333;
      }

      .doc-result-bat-download {
        padding: 12px 14px;
        color: #7F7F7F;
        margin-left: 10px;
        pointer-events: none;
        cursor: pointer;
        transition: .1s;

        &:hover {
          background-color: rgba(248, 248, 248, 0.6);
          border-radius: 4px;
        }

        i {
          margin-right: 4px;
        }

        &.bat-download-active {
          color: var(--themeColor);
          pointer-events: initial;
        }
      }

      .doc-result-pages {
        display: flex;
        align-items: center;
        flex: 1;
        width: 100%;
        justify-content: flex-end;

        .el-select {
          margin: 0 10px;
          width: 80px;
        }
      }
    }
  }

  .hot-doc {
    background-color: #FFFFFF;

    .hot-doc-title {
      padding: 15px 20px;
      font-size: 14px;
      font-weight: bold;
      color: #000000;
      border-bottom: 1px solid #F2F2F2;
    }

    .hot-doc-wrap {
      padding: 0 20px;
      min-height: 200px;
    }

    .hot-doc-list {
      border-bottom: 1px solid #F2F2F2;

      &:last-child {
        border-bottom: none;
      }
    }

    .hot-doc-link {
      display: flex;
      align-items: center;
      padding: 12px 0;
      cursor: pointer;
      color: #333333;
      line-height: 1.5;
      font-size: 13px;

      &:hover {
        .hot-doc-text {
          color: var(--themeColor);
        }
      }
    }

    .hot-doc-tag {
      font-size: 8px;
      background-color: var(--themeColor);
      color: #FFFFFF;
      padding: 0px 4px;
      margin-right: 8px;
      border-radius: 4px;
    }
  }

  .tag-group {
    display: flex;
    padding-bottom: 20px;
    justify-content: space-between;

    .tag-group-content {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      flex: 1;
      padding-right: 10px;
    }
  }

  .flex-end {
    display: flex;
    justify-content: flex-end;
  }

  @media (min-width: 1200px) {
    .container {
      width: 1400px;
    }
  }

  @media (max-width: 991px) {
    /deep/ .doc-result-footer .doc-result-pages .el-select {
      width: 90px !important;
    }
  }
</style>
